<template>
  <v-app>
    <core-app-bar />

    <core-drawer />

    <core-snackbar />

    <core-notificacao-alarme />

    <core-view />
  </v-app>
</template>

<script>
  import '@/assets/css/main.css';
  export default {
    components: {
      CoreAppBar: () => import('@/components/core/CoreAppBar'),
      CoreDrawer: () => import('@/components/core/CoreDrawer'),
      CoreView: () => import('@/components/core/CoreView'),
      CoreSnackbar: () => import('@/components/core/CoreSnackbar'),
      CoreNotificacaoAlarme: () => import('@/components/core/CoreNotificacaoAlarme'),
    },

    data: () => ({

    }),

  }
</script>
